const headings = {
    0: 'Web development',
    1: 'Blockchain Development',
    2: 'Mobile App Development',
    3: 'Graphic Designing',
    // Add more mappings as needed
  };

  const paragraphs = {
    0: 'Harnessing the latest web technologies, we craft dynamic and user-centric websites tailored to your needs, ensuring seamless digital experiences for your audience.',
    1: 'Pioneering decentralized solutions, we engineer cutting-edge blockchain applications and smart contracts, fostering trust and transparency in your digital transactions.',
    2: 'At the forefront of mobile innovation, we design and develop captivating applications across platforms, elevating your brand presence and engaging your users on the go.',
    3: 'Merging creativity with strategy, we conceptualize and design captivating visuals that resonate with your audience, enhancing brand identity and leaving a lasting impression.',
    // Add more mappings as needed
  };

  const hreflinks = {
    0: '/web-development',
    1: '/blockchain-development',
    2: '/mobile-app-development',
    3: '/graphic-designing',
    // Add more mappings as needed
  };

  export { headings, paragraphs,hreflinks };