import React from "react";
import { Link } from "react-router-dom";
function Aboutus() {

  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth" // Optional: Adds smooth scrolling behavior
    });
};


  return (
    <div className="aboutus">
      <section id="about" className="about section-bg">
        <div className="content-container">
          <div className="container">
            <div className="section-title">
              <h2>About Us</h2>
            </div>

            <div
              className="
              content
              d-flex
              flex-column-reverse flex-lg-row-reverse
              align-items-center
              justify-content-center
              gap-lg-5 gap-4
              p-3
            "
            >
              <div className="col-lg-5">
                <p className="justify">
                  At SystemLogic, we are trailblazers in web app development,
                  blockchain solutions, mobile application development, and
                  graphic design. Our passion lies in transforming ideas into
                  cutting-edge digital experiences, ensuring our clients thrive
                  in the dynamic world of technology.
                </p>

                <Link to="/about-us" onClick={scrollToTop}>Learn more</Link>
              </div>
              <div className="col-lg-5">
                <div>
                  <img
                    className="img-fluid"
                    src="./assets/images/about.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Aboutus;
