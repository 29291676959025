import React from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import Sidenavbar from "../components/sidenavbar";
import Preloader from "../components/preloader";


function Web() {
  return (
    <div>
      <Sidenavbar />
      <Link to="/"><Header /></Link>
      <div
        className="
        page-header
        position-relative
        d-flex
        align-items-center
        justify-content-center
        overflow-hidden
      "
      >
        <img
          className="position-absolute"
          src="../assets/images/services/digital.jpg"
          alt=""
        />
        <div
          className="
          position-absolute
          w-100
          h-100
          d-flex
          flex-column
          align-items-start
          justify-content-end
        "
        >
          <div className="position-relative w-100 h-100">
            <div className="content-container w-100 h-100">
              <div
                className="
                container
                w-100
                h-100
                d-flex
                flex-column
                align-items-start
                justify-content-center
              "
              >
                <p>
                  Web <span>Development</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="page">
        <div className="content-container">
          <div className="container">
            <p>
              Embark on the journey of digital transformation with us, your
              trusted partners in web development. We initiate this
              transformation with a customized plan that integrates cutting-edge
              technologies like React, Blockchain, and Next.js, guiding you to
              unprecedented success.
            </p>
            <p>
              At its core, Digital Transformation is a cultural shift within
              organizations. Many investments in new technologies fall short of
              ROI expectations because users are not adequately trained to adapt
              their daily business activities. As change enablers and
              implementation partners, we ensure that technology seamlessly
              integrates where it's needed most. Our expertise in Digital
              Transformation focuses on:
            </p>
            <ul>
              <li>
                <strong>Strategic Planning Systems Implementation:</strong>{" "}
                Strategically implement technologies like React, Blockchain, and
                Next.js to align with your business goals.
              </li>
              <li>
                <strong>Organizational Network Amplification:</strong> Enhance
                connectivity and collaboration within your organization through
                advanced network solutions powered by cutting-edge web
                development technologies.
              </li>
              <li>
                <strong>Digital Footprint Improvement:</strong> Boost your
                online presence and engagement through the strategic use of
                technologies like React and Next.js.
              </li>
              <li>
                <strong>New Process & Technology Adoption:</strong> Seamlessly
                integrate new processes and technologies, ensuring a smooth
                transition for your team with the power of React, Blockchain,
                and Next.js.
              </li>
              <li>
                <strong>Customized IT and Business Solutions:</strong>{" "}
                Tailor-made solutions powered by React, Blockchain, and Next.js
                to meet your unique IT and business requirements, fostering
                efficiency and innovation.
              </li>
              <li>
                <strong>Organizational Agility:</strong> Equip your organization
                with the agility to respond swiftly to changing business rules
                using the latest web development technologies.
              </li>
            </ul>
            <p>
              Our comprehensive web development services include Systems
              Integration, Business Process Outsourcing, Engineering &
              Manufacturing Solutions, Analytics, Business Intelligence, CRM,
              ERP, R&D outsourcing, and robust testing solutions. We stand out
              as a premier web development partner, redefining, digitizing, and
              managing business processes with technologies like React,
              Blockchain, and Next.js, ultimately improving customer
              experiences, accelerating efficiency, and lowering operating costs
              for result-oriented growth.
            </p>
            <p>
              Join hands with us to redefine your digital landscape and position
              your business for sustained success, powered by the innovations of
              React, Blockchain, and Next.js.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
export default Web;




{/*  <div className="web-sec1">
              <h1 className="text-start">
                Service Description
              </h1>
              <p style={{textAlign: 'justify',}} >
                Web Development is the process of creating and building websites
                or web applications that are accessible via the internet. It
                involves a range of tasks, including designing the user
                interface, developing the back-end functionality, and ensuring
                that the website or web app is responsive and user-friendly.Web
                development encompasses both the visual aspects of a website and
                its underlying technical infrastructure.
              </p>
            </div> */}