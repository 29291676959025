import React from "react";
import { Link } from "react-router-dom";
function Product() {

  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth" // Optional: Adds smooth scrolling behavior
    });
};

  return (
    <div className="product">
      <section id="products" className="products">
    <div className="content-container">
      <div className="container">
        <div className="section-title">
          <h2>Products</h2>
        </div>

        <div className="product-cards row gap-3 justify-content-center">
          <div className="
                col-xl-6
                d-flex
                flex-column flex-sm-row
                align-items-stretch
                row
              ">
            <div className="
                  img-container
                  col-sm-5
                  position-relative
                  d-flex
                  align-items-center
                  justify-content-center
                  overflow-hidden
                ">
              <img className="position-absolute" src="./assets/images/products/Software-development.jpg" alt="" />
            </div>
            <div className="col-sm-7 box-card position-relative">
              <div className="section-header d-flex align-items-center gap-3">
                <div className="icon"><i className="fal fa-laptop-code"></i></div>
                <h4>Software Development</h4>
              </div>

              <p>
              With a dedicated focus on innovation and excellence, we bring together cutting-edge technologies and industry expertise to deliver high-quality software products and services. From concept to deployment, we are committed to transforming ideas into impactful digital solutions that drive business growth and success.
              </p>
              <Link className="" to="/software-development" onClick={scrollToTop}>See Details</Link>
            </div>
          </div>

          <div className="
                col-xl-6
                d-flex
                flex-column flex-sm-row
                align-items-stretch
                row
              ">
            <div className="
                  img-container
                  col-sm-5
                  position-relative
                  d-flex
                  align-items-center
                  justify-content-center
                  overflow-hidden
                ">
              <img className="position-absolute" src="./assets/images/products/erp.jpg" alt="" />
            </div>
            <div className="col-sm-7 box-card position-relative">
              <div className="section-header d-flex align-items-center gap-3">
                <div className="icon"><i className="fal fa-chart-network"></i></div>
                <h4>Dapps, Defi, NFT's Development</h4>
              </div>

              <p>
              At our forefront, we specialize in pioneering decentralized applications (DApps), decentralized finance (DeFi) solutions, and cutting-edge NFT (Non-Fungible Token) development. With a keen focus on innovation, we craft bespoke solutions that redefine digital interactions, empower financial ecosystems, and unlock new avenues of value creation in the blockchain space.
              </p>

              <Link className="" to="/dapps-nft-development" onClick={scrollToTop}>See Details</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
        </div>
  );
}

export default Product;
