import React from "react";
import { Link } from "react-router-dom";

function footer() {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth" // Optional: Adds smooth scrolling behavior
        });
    };

    return (
        <div>
            <footer id="footer">
                <div className="content-container">
                    <div className="footer-top">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 footer-contact">
                                    <h3>System Logic</h3>
                                    <h6><strong>Pakistan</strong></h6>
                                    <p>
                                        413, B block <br />
                                        Peoples colony # 1, Faisalabad<br />
                                        Pakistan <br />
                                        <strong>Phone:</strong> +92 327 7928589 <br />
                                    </p>
                                    <br />                                    
                                    <strong>Email:</strong> systemlogicorg@gmai.com<br />
                                </div>
                                <div className="col-lg-2 col-md-6 footer-links">
                                    <h4>Useful Links</h4>
                                    <ul className="d-flex flex-column align-items-center">
                                        <li>
                                            <Link to="#hero" onClick={scrollToTop}>Home</Link>
                                        </li>
                                        <li>
                                            <a href="#services">Services</a>
                                        </li>
                                        <li>
                                            <a href="#products">Products</a>
                                        </li>
                                        <li>
                                            <a href="#about">About</a>
                                        </li>                                        
                                        <li>
                                            <Link to="/contact">Contact</Link>
                                        </li>                                        
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-md-6 footer-links">
                                    <h4>Our Services</h4>
                                    <ul className="d-flex flex-column align-items-center">
                                        <li>
                                            <Link to="/web-development" onClick={scrollToTop}>Web Development</Link>
                                        </li>
                                        <li>
                                            <Link to="/blockchain-development" onClick={scrollToTop} rel="noopener noreferrer">Blockchain Development</Link>
                                        </li>
                                        <li>
                                            <Link to="/mobile-app-development" onClick={scrollToTop} rel="noopener noreferrer">Mobile App Development</Link>
                                        </li>
                                        <li>
                                            <Link to="/graphic-designing" onClick={scrollToTop} rel="noopener noreferrer">Graphic Designing
                                            </Link>
                                        </li>
                                    </ul>                                   
                                </div>
                                <div className="col-lg-2 col-md-6 footer-links">
                                    <h4>Our Products</h4>
                                    <ul className="d-flex flex-column align-items-center">
                                        <li>
                                            <Link to="/software-development" onClick={scrollToTop} rel="noopener noreferrer">Software Development</Link>
                                        </li>
                                        <li>
                                            <Link to="/dapps-nft-development" onClick={scrollToTop} rel="noopener noreferrer">Dapps, Defi, NFT's Development</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-2 col-md-6 footer-links">
                                    <h4>Our Socials</h4>
                                    <div className="social-links mt-3 d-flex justify-content-center">
                                        <a href="https://www.systemlogic.org/" className="facebook" target="_blank"><i
                                            className="fab fa-facebook-f"></i></a>
                                        <a href="https://www.systemlogic.org/" className="linkedin" target="_blank"><i
                                            className="fab fa-linkedin-in"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container py-4">
                        <div className="copyright">
                            &copy; Copyright 2024<strong><span> System Logic. Pvt. Ltd</span></strong>. All Rights Reserved
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default footer;