import React from "react";
import { Link } from "react-router-dom";

function services() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling behavior
    });
  };

  return (
    <div>
      <section id="services" className="services section-bg">
        <div className="content-container">
          <div className="container">
            <div className="section-title">
              <h2>Services</h2>
            </div>

            <div className="row">
              <div className="col-xl-3 col-md-6 d-flex align-items-stretch">
                <div className="box-card">
                  <div className="section-header d-flex align-items-center justify-content-start gap-3">
                    <div className="icon">
                      <i className="fal fa-random fa-3x"></i>
                    </div>
                    <h4>Web Development</h4>
                  </div>
                  <p>
                    Harnessing the latest web technologies, we craft dynamic and
                    user-centric websites tailored to your needs, ensuring
                    seamless digital experiences for your audience.
                  </p>
                  <Link to="/web-development" onClick={scrollToTop}>
                    Read More
                  </Link>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                <div className="box-card">
                  <div
                    className="
                    section-header
                    d-flex
                    align-items-center
                    justify-content-start
                    gap-3
                  "
                  >
                    <div className="icon">
                      <i className="fal fa-briefcase fa-3x"></i>
                    </div>
                    <h4>Blockchain Development</h4>
                  </div>

                  <p>
                    Pioneering decentralized solutions, we engineer cutting-edge
                    blockchain applications and smart contracts, fostering trust
                    and transparency in your digital transactions.
                  </p>

                  <Link to="/blockchain-development" onClick={scrollToTop}>
                    Read More
                  </Link>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0">
                <div className="box-card">
                  <div
                    className="
                    section-header
                    d-flex
                    align-items-center
                    justify-content-start
                    gap-3
                  "
                  >
                    <div className="icon">
                      <i className="fal fa-usd-circle fa-3x"></i>
                    </div>
                    <h4>Mobile App Development</h4>
                  </div>

                  <p>
                    At the forefront of mobile innovation, we design and develop
                    captivating applications across platforms, elevating your
                    brand presence and engaging your users on the go.
                  </p>

                  <Link to="/mobile-app-development" onClick={scrollToTop}>
                    Read More
                  </Link>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0">
                <div className="box-card">
                  <div
                    className="
                    section-header
                    d-flex
                    align-items-center
                    justify-content-start
                    gap-3
                  "
                  >
                    <div className="icon">
                      <i className="fal fa-laptop fa-3x"></i>
                    </div>
                    <h4>Graphic Designing</h4>
                  </div>

                  <p>
                    Merging creativity with strategy, we conceptualize and
                    design captivating visuals that resonate with your audience,
                    enhancing brand identity and leaving a lasting impression.
                  </p>

                  <Link to="/graphic-designing" onClick={scrollToTop}>
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default services;
