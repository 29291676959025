import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";


function Header() {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        // Event listener to track scroll position
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            // Set the state based on the scroll position
            setIsScrolled(scrollPosition > 0);
        };

        // Attach the event listener
        window.addEventListener("scroll", handleScroll);

        // Remove the event listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const routerLocation = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [routerLocation]);

    return (
        <div>
            <div
                id="logo-header"
                className={`d-flex align-items-center ${isScrolled ? "header-scrolled" : ""}`}
            >
                <div className="logo">
                    <h3 className="">
                        
                        <img className="" style={{marginLeft:"10px"}} height="80" src="./assets/images/header2.png" alt="" />
                                            </h3>
                </div>
            </div>
        </div>
    );
}

export default Header;
