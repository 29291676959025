import React, { useState, useEffect } from "react";
import { headings,paragraphs,hreflinks } from "../ConstantValue";

function Herosection() {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            // Increment the activeIndex and loop back to the first item if needed
            setActiveIndex((prevIndex) =>
                prevIndex === 3 ? 0 : prevIndex + 1
            );
        }, 3000);

        // Clear the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array means this effect runs once when the component mounts

    const handleIndicatorClick = (index) => {
        // Set the activeIndex when an indicator is clicked
        setActiveIndex(index);
    };

    return (
        <div className="hero" id="hero">
            <section id="hero">
                <div className="content-container">
                    <div className="hero-container">
                        <div id="heroCarousel" className="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="8000" data-bs-pause="false">
                            <ol className="carousel-indicators" id="hero-carousel-indicators">
                                {Array.from({ length: 4 }).map((_, index) => (
                                    <li
                                        key={index}
                                        data-bs-target="#heroCarousel"
                                        data-bs-slide-to={index}
                                        class={index === activeIndex ? "active" : ""}
                                        onClick={() => handleIndicatorClick(index)}
                                    ></li>
                                ))}
                            </ol>
                            <div className="carousel-inner" role="listbox">
                                {Array.from({ length: 4 }).map((_, index) => (
                                    <div
                                        key={index}
                                        className={`carousel-item ${index === activeIndex ? "active" : ""}`}
                                    >
                                        {generateCarouselContent(activeIndex)}
                                        
                                                                                
                                    </div>
                                    
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}



const generateCarouselContent = (activeIndex) => {
   
    
    return (
      <div className="carousel-container">
        <div className="carousel-left">
          <div className="carousel-content">
            <h2 className="animate__animated animate__fadeInDown">
              {headings[activeIndex]}
            </h2>
            <p className="animate__animated animate__fadeInUp">
                
             {paragraphs[activeIndex]}
            </p>
            <a
              href={hreflinks[activeIndex]}
              className="btn-read-more animate__animated animate__fadeInUp scrollto"
            >
              Read More
            </a>
          </div>
        </div>
        <div className="carousel-right" >

          <img
            src={`./assets/images/services/${activeIndex}.jpg`}
            alt=""
            className="animate__animated animate__fadeInRight"
          />
        </div>
      </div>
    );
  };

export default Herosection;
