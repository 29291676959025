import React from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import Sidenavbar from "../components/sidenavbar";
import Preloader from '../components/preloader';
import { useEffect } from "react";

function Contact() {
  
  const routerLocation = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [routerLocation]);
return (
    <div id="contact">
       
      <Sidenavbar />
      <Link to="/"> 
        <Header />
      </Link>
      <div
        className="
        page-header
        position-relative
        d-flex
        align-items-center
        justify-content-center
        overflow-hidden
      "
      >
        <img
          className="position-absolute"
          src="./assets/images/contact-us.jpg"
          alt=""
        />
        <div
          className="
          position-absolute
          w-100
          h-100
          d-flex
          flex-column
          align-items-start
          justify-content-end
        "
        >
          <div className="position-relative w-100 h-100">
            <div className="content-container w-100 h-100">
              <div
                className="
                container
                w-100
                h-100
                d-flex
                flex-column
                align-items-start
                justify-content-center
              "
              >
                <p>
                  <span>Contact Us</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="contact">
        <div className="content-container">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div className="box-card mb-4">
                  <div className="icon">
                    <i className="fal fa-user-friends"></i>
                  </div>
                  <h3>Follow Us</h3>
                  <div className="social-links mt-3">
                    <a
                      href="https://www.systemlogic.org/"
                      className="facebook"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.systemlogic.org/"
                      className="linkedin"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 col-md-12">
                <div className="box-card mb-4">
                  <div className="icon">
                    <i className="fal fa-envelope"></i>
                  </div>
                  <h3>Email</h3>
                  <p>systemlogicorg@gamil.com</p>
                  <br />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div className="box-card mb-4">
                  <div className="icon">
                    <i className="fal fa-phone"></i>
                  </div>
                  <h3>Pakistan</h3>
                  <p>+92 327 7928589</p>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="box-card mb-4">
                  <div className="icon">
                    <i className="fal fa-map-marker-alt"></i>
                  </div>
                  <h3>Pakistan</h3>
                  <p>413, B block Peoples colony # 1, Faisalabad, Pakistan</p>
                </div>
              </div>
            </div>

            <div className="row mt-3 map">
              <div className="col-lg-12">
                <h3>Pakistan</h3>
                <iframe
                  className="border-0 w-100 h-100 mb-4 mb-lg-0"
                  style={{ height: "275px" }}
                  allowfullscreen
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13620.732902760588!2d73.09220533551938!3d31.40907753823656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3922680ee281e47f%3A0x9d53647973bef23c!2sBlock%20B%20People&#39;s%20Colony%20No%201%2C%20Faisalabad%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1708866856110!5m2!1sen!2s"
                  width="600"
                  height="450"
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="mt-5">
              <form action="forms/contact.php" method="post" role="form">
                <div className="row">
                  <div className="col-12 col-md-6 form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      placeholder="Name*"
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="organizationName"
                      id="organizationName"
                      placeholder="Organization Name*"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Email Address*"
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="contactNum"
                      id="contactNum"
                      placeholder="Contact Number"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Subject*"
                    required
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="5"
                    placeholder="Message*"
                    required
                  ></textarea>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-send"
                    id="contactFormBtn"
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
export default Contact;
