import React from "react";
import { Link, useLocation } from "react-router-dom";
import Header from '../components/header';
import Footer from '../components/footer';
import Sidenavbar from '../components/sidenavbar';
import Preloader from "../components/preloader";

function About() {
  return (
    <div> 
      <Sidenavbar />
      <Link to="/"> 
        <Header />
      </Link>
      <div
        className="
        page-header
        position-relative
        d-flex
        align-items-center
        justify-content-center
        overflow-hidden
      "
      >
        <img
          className="position-absolute"
          src="./assets/images/about-us.jpg"
          alt=""
        />
        <div
          className="
          position-absolute
          w-100
          h-100
          d-flex
          flex-column
          align-items-start
          justify-content-end
        "
        >
          <div className="position-relative w-100 h-100">
            <div className="content-container w-100 h-100">
              <div
                className="
                container
                w-100
                h-100
                d-flex
                flex-column
                align-items-start
                justify-content-center
              "
              >
                <p>
                  <span>About Us</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="about page">
        <div className="content-container">
          <div className="container">
            <div className="section-title">
              <h2>About Us</h2>
            </div>

            <div className="float-lg-start about-image-design">
              <img
                className="img-fluid"
                src="./assets/images/about.jpg"
                alt=""
              />
            </div>
            <p>
              Welcome to SystemLogic, where innovation meets expertise to
              redefine the digital landscape. As pioneers in web app
              development, we specialize in crafting seamless, user-centric
              applications that empower businesses to thrive in the online
              realm. Our proficiency extends to blockchain solutions, where we
              harness the decentralized power of this groundbreaking technology
              to create secure and transparent solutions for our clients.{" "}
            </p>
            <p>
              In the realm of mobile application development, our team, led by
              experienced developers, ensures the delivery of engaging and
              functional apps aligned with the latest industry standards.
              Complementing our technical prowess is our graphic design team,
              dedicated to infusing visual aesthetics into every project. We
              take pride in establishing a strong and memorable brand identity
              for our clients.{" "}
            </p>
            <p className="m-0">
              At SystemLogic, our unwavering commitment to excellence is evident
              in our ability to transform ideas into impactful digital
              realities. We are dedicated to propelling our clients towards
              unparalleled success in the dynamic and ever-evolving digital
              landscape.
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
export default About;
