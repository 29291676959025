import React from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import Sidenavbar from "../components/sidenavbar";
import Preloader from "../components/preloader";

import { useEffect } from "react";

function Team() {

  const routerLocation = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [routerLocation]);
  return (
    <div>
      <Sidenavbar />
      <Link to="/"> 
        <Header />
      </Link>
      <div
        className="
        page-header
        position-relative
        d-flex
        align-items-center
        justify-content-center
        overflow-hidden
      "
      >
        <img
          className="position-absolute"
          src="./assets/images/team.jpg"
          alt=""
        />
        <div
          className="
          position-absolute
          w-100
          h-100
          d-flex
          flex-column
          align-items-start
          justify-content-end
        "
        >
          <div className="position-relative w-100 h-100">
            <div className="content-container w-100 h-100">
              <div
                className="
                container
                w-100
                h-100
                d-flex
                flex-column
                align-items-start
                justify-content-center
              "
              >
                <p>
                  <span>Team</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="team-section section-bg">
        <div className="content-container">
          {/* <div className="container">
                        <article className="d-flex align-items-center justify-content-center">
                            <div className="row align-items-center">
                                <div className="col-lg-6 d-flex align-items-center justify-content-start">
                                    <img src="./assets/images/team/bilal.jpg" alt="" />
                                </div>

                                <div className="info-box col-lg-6 bg-light p-4">
                                    <div className="name"><span>Bilal Anwer</span> Khan</div>
                                    <div className="designation">Co-Founder & CEO</div>
                                    <div className="details">
                                        <p>
                                            Bilal is a Seasoned Change enabler, Thought Leader and
                                            Startup Investor with strong experience of strategic and
                                            operational management in FMCF and Information Technology
                                            industries with a finance and technology background across
                                            GCC including Saudi Arabia, UAE, Bahrain and Pakistan.
                                        </p>
                                        <p>
                                            He has assumed various roles in Group Finance Controller,
                                            Corporate Finance, Reporting & Regulatory Compliance
                                            capacities and has proven his mettle. Bilal’s vast
                                            experience of working in multi geographical &
                                            multi-dimensional commodities based international
                                            conglomerates has contributed millions of dollars in terms
                                            of ROI and positive EBITDA. 15+ years in the corporate
                                            world, he has been leading ecommerce Startups and other
                                            business ventures for their digital transformation
                                            experience, compliance, investor and external consultant
                                            relations leading them to reach the summit with sustainable
                                            growth.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </article>

                        <br />
                        <hr />
                        <br />

                        <article className="d-flex align-items-center justify-content-center">
                            <div className="row flex-column-reverse flex-lg-row align-items-center">
                                <div className="info-box col-lg-6 bg-light p-4">
                                    <div className="name"><span>Wasif</span> Khan</div>
                                    <div className="designation">Co-Founder</div>
                                    <div className="details">
                                        <p>
                                            Wasif is an experienced financial, taxation consultant and
                                            an advisor on company restructuring & system optimization.
                                            Accounting / Finance, Taxation Trainer and Coach with
                                            background of ACMA UK. He has served as tax advisor to large
                                            scale manufacturing and fashion stores, as an expert in
                                            pricing, development strategy & implementation. His
                                            experience stretches across UK & Pakistan.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-lg-6 d-flex align-items-center justify-content-end">
                                    <img src="./assets/images/team/wasif.jpg" alt="" />
                                </div>
                            </div>
                        </article>

                        <br />
                        <hr />
                        <br />

                        <article className="d-flex align-items-center justify-content-center">
                            <div className="row align-items-center">
                                <div className="col-lg-6 d-flex align-items-center justify-content-start">
                                    <img src="./assets/images/team/tariq.jpg" alt="" />
                                </div>

                                <div className="info-box col-lg-6 bg-light p-4">
                                    <div className="name"><span>Tariq</span> Siddiqui</div>
                                    <div className="designation">Business Development</div>
                                    <div className="details">
                                        <p>
                                            Tariq is a visionary business veteran with over 3 decades of
                                            entrepreneurship, enterprise & Strategy development,
                                            business enhancement & process optimization. He successfully
                                            ran his own textile buying house for more than a decade and
                                            worked with international retailers in challenging
                                            marketplaces such as US, UK, EU & Japan.
                                        </p>
                                        <p>
                                            He has a vast experience of managing multimillion dollar
                                            business portfolios and has executed long and short term
                                            business strategies to improve industry best practices in
                                            his highly successful ventures. His invaluable network of
                                            global business leaders & industry professionals has given
                                            him an unparalleled edge in various industries and brings a
                                            lot of worth to any company he joins or starts.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </article>

                        <br />
                        <hr />
                        <br />

                        <article className="d-flex align-items-center justify-content-center">
                            <div className="row flex-column-reverse flex-lg-row align-items-center">
                                <div className="info-box col-lg-6 bg-light p-4">
                                    <div className="name"><span>Kaunain</span> Faruqui</div>
                                    <div className="designation">HR Consultant</div>
                                    <div className="details">
                                        <p>
                                            Kaunain has over 10 years of experience as an HR
                                            professional. With a Master's degree in Public
                                            Administration from KU, he has worked as an HR professional
                                            for top employers including Bank Al-Falah, Mobilink and
                                            Dawlance Group.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-lg-6 d-flex align-items-center justify-content-end">
                                    <img src="./assets/images/team/kaunain.jpg" alt="" />
                                </div>
                            </div>
                        </article>

                        <br />
                        <hr />
                        <br />

                        <article className="d-flex align-items-center justify-content-center">
                            <div className="row align-items-center">
                                <div className="col-lg-6 d-flex align-items-center justify-content-start">
                                    <img src="./assets/images/team/tahir.jpeg" alt="" />
                                </div>

                                <div className="info-box col-lg-6 bg-light p-4">
                                    <div className="name"><span>Tahir</span> Akhtar</div>
                                    <div className="designation">Partner</div>
                                    <div className="details">
                                        <p>
                                            During his career starting in year 2001, Tahir Akhtar has
                                            remained employed with several reputable international
                                            software development companies including Gameview Studios
                                            (Subsidiary of DeNA Japan), TimeXperts Pvt. Ltd (formerly
                                            Workforce Software), Sibisoft Pvt Ltd (Global Northstar) and
                                            Pivotal Black.
                                        </p>
                                        <p>
                                            He is a full stack software developer and software architect
                                            with special interest in Java and cloud based platforms.
                                        </p>
                                        <p>
                                            Tahir has recruited and trained over a dozen engineers,
                                            architected several cloud based systems, done java backend
                                            and web frontend development, led mobile development teams,
                                            delivered Oracle middleware integration project and provided
                                            business process consultancy to company’s e-commerce client.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div> */}

          <div className="container">
            <article className="d-flex align-items-center justify-content-center">
              <div className="row align-items-center">
                <div className="col-lg-6 d-flex align-items-center justify-content-start">
                  <img
                    src="./assets/images/team/muhammad_abdullah.jpg"
                    alt="Muhammad Abdullah"
                  />
                </div>

                <div className="info-box col-lg-6 bg-light p-4">
                  <div className="name">
                    <span>Muhammad Abdullah</span>
                  </div>
                  <div className="designation">Co-Founder & CEO</div>
                  <div className="details">
                    <p>
                      With a visionary mindset, Muhammad Abdullah spearheads our
                      company as the Co-founder and CEO. His expertise as a Full
                      Stack Blockchain Developer ensures that our technical
                      foundation is robust and innovative, steering the company
                      towards cutting-edge solutions in the blockchain space.
                    </p>
                    <p>
                      Muhammad Abdullah's hands-on approach ensures a robust
                      technical foundation, laying the groundwork for innovation
                      and sustainable growth.
                    </p>
                  </div>
                </div>
              </div>
            </article>

            <br />
            <hr />
            <br />

            <article className="d-flex align-items-center justify-content-center">
              <div className="row flex-column-reverse flex-lg-row align-items-center">
                <div className="info-box col-lg-6 bg-light p-4">
                  <div className="name">
                    <span>Namir Rehman</span>
                  </div>
                  <div className="designation">Co-Founder & CTO</div>
                  <div className="details">
                    <p>
                      Namir Rehman, as the Co-founder and Chief Technology
                      Officer, is instrumental in shaping our technological
                      landscape. With a wealth of experience as a Full Stack
                      Developer, Namir seamlessly integrates front-end and
                      back-end technologies. His technical prowess not only
                      ensures the soundness of our products but also plays a
                      crucial role in making them user-friendly.
                    </p>
                    <p>
                      Namir's commitment to technical excellence drives the
                      company's technological advancements.
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 d-flex align-items-center justify-content-end">
                  <img
                    src="./assets/images/team/namir.jpg"
                    alt="Namir Rehman"
                  />
                </div>
              </div>
            </article>

            <br />
            <hr />
            <br />

            <article className="d-flex align-items-center justify-content-center">
              <div className="row align-items-center">
                <div className="col-lg-6 d-flex align-items-center justify-content-start">
                  <img
                    src="./assets/images/team/ubaid_ur_rehman.jpg"
                    alt="Ubaid ur Rehman"
                  />
                </div>

                <div className="info-box col-lg-6 bg-light p-4">
                  <div className="name">
                    <span>Ubaid ur Rehman</span>
                  </div>
                  <div className="designation">Co-Founder & COO</div>
                  <div className="details">
                    <p>
                      Ubaid ur Rehman, serving as the Co-founder and Chief
                      Operating Officer, brings a strategic mindset and
                      operational excellence to our leadership team. As a Full
                      Stack Developer, Ubaid leverages his technical acumen to
                      efficiently implement business strategies. His role is
                      pivotal in driving the company towards operational goals,
                      ensuring a harmonious blend of technology and business
                      objectives.
                    </p>
                  </div>
                </div>
              </div>
            </article>

            <br />
            <hr />
            <br />

            <article className="d-flex align-items-center justify-content-center">
              <div className="row flex-column-reverse flex-lg-row align-items-center">
                <div className="info-box col-lg-6 bg-light p-4">
                  <div className="name">
                    <span>Ahsan Riaz</span>
                  </div>
                  <div className="designation">Marketing Head</div>
                  <div className="details">
                    <p>
                      Ahsan Riaz, our Marketing Head, is a driving force behind
                      the promotion of our brand and solutions. With a keen
                      understanding of market dynamics, Ahsan crafts and
                      executes marketing strategies to enhance our company's
                      visibility. His leadership in the marketing domain ensures
                      that our offerings resonate with the target audience,
                      contributing to the overall success of the company.
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 d-flex align-items-center justify-content-end">
                  <img
                    src="./assets/images/team/ahsan_riaz.jpg"
                    alt="Ahsan Riaz"
                  />
                </div>
              </div>
            </article>

            <br />
            <hr />
            <br />

            <br />
            <hr />
            <br />

            <article className="d-flex align-items-center justify-content-center">
              <div className="row align-items-center">
                <div className="col-lg-6 d-flex align-items-center justify-content-start">
                  <img
                    src="./assets/images/team/ikram.jpeg"
                    alt="Muhammad Ikram"
                  />
                </div>

                <div className="info-box col-lg-6 bg-light p-4">
                  <div className="name">
                    <span>Muhammad Ikram</span>
                  </div>
                  <div className="designation">
                    Lead Mobile Application Developer
                  </div>
                  <div className="details">
                    <p>
                      Muhammad Ikram, our Lead Mobile Application Developer, is
                      a creative force leading our mobile development team. With
                      a focus on both creativity and technical prowess, Ikram
                      ensures the delivery of engaging and functional mobile
                      applications. His leadership aligns the mobile development
                      efforts with the latest industry standards, keeping our
                      products at the forefront of innovation.
                    </p>
                  </div>
                </div>
              </div>
            </article>

            <br />
            <hr />
            <br />

            <article className="d-flex align-items-center justify-content-center">
              <div className="row flex-column-reverse flex-lg-row align-items-center">
                <div className="info-box col-lg-6 bg-light p-4">
                  <div className="name">
                    <span>Amman Rehman</span>
                  </div>
                  <div className="designation">Lead Graphic Designer</div>
                  <div className="details">
                    <p>
                      Amman Rehman, serving as our Lead Graphic Designer, is the
                      artistic visionary behind our brand and products. His
                      creative vision and design expertise contribute
                      significantly to establishing a strong visual identity for
                      the company. Amman ensures that our communication is not
                      only impactful but also visually appealing, making a
                      lasting impression on our audience.
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 d-flex align-items-center justify-content-end">
                  <img
                    src="./assets/images/team/amman.jpeg"
                    alt="Amman Rehman"
                  />
                </div>
              </div>
            </article>

            <br />
            <hr />
            <br />

            <article className="d-flex align-items-center justify-content-center">
              <div className="row align-items-center">
                <div className="col-lg-6 d-flex align-items-center justify-content-start">
                  <img src="./assets/images/team/basit.jpg" alt="Basit" />
                </div>

                <div className="info-box col-lg-6 bg-light p-4">
                  <div className="name">
                    <span>Basit</span>
                  </div>
                  <div className="designation">Graphic Designer</div>
                  <div className="details">
                    <p>
                      Basit, a valuable member of our design team, showcases his
                      talent as a Graphic Designer. With a keen eye for detail
                      and a passion for design, Basit plays a crucial role in
                      creating captivating visuals. His contributions enhance
                      our brand presence and user experience, making our
                      products visually compelling and memorable.
                    </p>
                  </div>
                </div>
              </div>
            </article>

            <br />
            <hr />
            <br />

            <article className="d-flex align-items-center justify-content-center">
              <div className="row flex-column-reverse flex-lg-row align-items-center">
                <div className="info-box col-lg-6 bg-light p-4">
                  <div className="name">
                    <span>Hafiz Umer Farooq</span>
                  </div>
                  <div className="designation">Social Media Marketing Head</div>
                  <div className="details">
                    <p>
                      Hafiz Umer Farooq leads our social media marketing
                      initiatives with strategic insights and a creative
                      approach. As the Social Media Marketing Head, he brings a
                      wealth of experience in creating and implementing
                      effective social media strategies. His expertise ensures
                      that our brand maintains a strong and engaging presence
                      across various social media platforms.
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 d-flex align-items-center justify-content-end">
                  {/* Insert Hafiz Umer Farooq's image path here */}
                  <img
                    src="./assets/images/team/umer.jpeg"
                    alt="Hafiz Umer Farooq"
                  />
                </div>
              </div>
            </article>

            <br />
            <hr />
            <br />

            <article className="d-flex align-items-center justify-content-center">
              <div className="row align-items-center">
                <div className="col-lg-6 d-flex align-items-center justify-content-start">
                  <img
                    src="./assets/images/team/muneeb_ch.jpeg"
                    alt="M.Muneeb Shahid"
                  />
                </div>

                <div className="info-box col-lg-6 bg-light p-4">
                  <div className="name">
                    <span>M. Muneeb Shahid</span>
                  </div>
                  <div className="designation">Frontend Web Developer</div>
                  <div className="details">
                    <p>
                      Muneeb Shahid, a valuable member of our development team,
                      specialize in frontend development with expertise in HTML,
                      CSS, Bootstrap, Tailwind CSS, JavaScript, and React.js.
                      With a focus on blending design aesthetics with robust
                      functionality, I create engaging user interfaces.
                      Leveraging frontend frameworks like Bootstrap and Tailwind
                      CSS, I craft visually stunning, responsive designs.
                    </p>
                    <p>
                      Proficient in JavaScript and React.js, I bring
                      interactivity and dynamism to projects, optimizing user
                      experience and ensuring seamless performance across
                      devices.
                    </p>
                  </div>
                </div>
              </div>
            </article>
            <br />
            <hr />
            <br />

            <article className="d-flex align-items-center justify-content-center">
              <div className="row flex-column-reverse flex-lg-row align-items-center">
                <div className="info-box col-lg-6 bg-light p-4">
                  <div className="name">
                    <span>Muhammad Asad Aslam</span>
                  </div>
                  <div className="designation">Digital Marketing Expert</div>
                  <div className="details">
                    <p>
                      As an experienced Digital Marketing Expert, I bring a
                      passion for driving business success through online
                      platforms. With expertise in SEO, SEM, social media, and
                      content marketing, I craft innovative strategies that
                      resonate with target audiences. I thrive on staying ahead
                      of industry trends and optimizing performance through
                      analytics. Committed to surpassing expectations, I aim to
                      elevate your company's digital presence to new heights.
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 d-flex align-items-center justify-content-end">
                  <img
                    src="./assets/images/team/asad_aslam.jpeg"
                    alt="Asad Aslam"
                  />
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
export default Team;
