import React from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import Sidenavbar from "../components/sidenavbar";
import Preloader from '../components/preloader';

function MobileApp() {
  return (
    <div>
       
      <Sidenavbar />
      <Link to="/"><Header /></Link>
      <div
        className="
        page-header
        position-relative
        d-flex
        align-items-center
        justify-content-center
        overflow-hidden
      "
      >
        <img
          className="position-absolute"
          src="../assets/images/services/finance.jpg"
          alt=""
        />
        <div
          className="
          position-absolute
          w-100
          h-100
          d-flex
          flex-column
          align-items-start
          justify-content-end
        "
        >
          <div className="position-relative w-100 h-100">
            <div className="content-container w-100 h-100">
              <div
                className="
                container
                w-100
                h-100
                d-flex
                flex-column
                align-items-start
                justify-content-center
              "
              >
                <p>
                  Mobile App <span> Development</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="page">
        <div className="content-container">
          <div className="container">
            <p>
              Elevate your business with SystemLogic' cutting-edge mobile
              application development services. Unleash the power of mobile
              technology to enhance customer engagement, streamline operations,
              and propel your business to new heights. Partner with us for a
              mobile app experience that goes beyond expectations:
            </p>
            <p>
              Our dedicated team of mobile app developers ensures that your
              business stays at the forefront of innovation. From concept to
              deployment, we craft custom mobile applications that align with
              your business goals and captivate your audience.
            </p>
            <ul>
              <li>
                <strong>Android and iOS Excellence:</strong> Reach a wider
                audience by harnessing the capabilities of both Android and iOS
                platforms. Our developers create seamless and user-friendly
                mobile apps tailored to each operating system.
              </li>
              <li>
                <strong>Customer-Centric Solutions:</strong> We prioritize user
                experience, creating mobile applications that resonate with your
                audience and keep them coming back for more. From intuitive
                interfaces to innovative features, we ensure your app stands out
                in the crowded market.
              </li>
              <li>
                <strong>Enterprise Mobility:</strong> Streamline your business
                operations with enterprise-grade mobile solutions. Our expertise
                extends to crafting mobile apps that enhance productivity,
                collaboration, and overall efficiency within your organization.
              </li>
              <li>
                <strong>Cross-Platform Compatibility:</strong> Save time and
                resources with cross-platform mobile app development. Our
                developers utilize frameworks that ensure your app performs
                seamlessly on various devices, providing a consistent user
                experience.
              </li>
            </ul>
            <br />
            <p>
              Partnering with SystemLogic for mobile application development
              brings a myriad of benefits for your business:
            </p>
            <ul>
              <li>
                <strong>Enhanced Customer Engagement:</strong> Connect with your
                audience on a deeper level through feature-rich mobile apps that
                cater to their needs and preferences.
              </li>
              <li>
                <strong>Operational Efficiency:</strong> Streamline your
                internal processes with mobile applications designed to enhance
                collaboration, communication, and overall efficiency within your
                organization.
              </li>
              <li>
                <strong>Market Differentiation:</strong> Stand out in the
                competitive landscape with a unique and visually appealing
                mobile app that reflects your brand's identity.
              </li>
              <li>
                <strong>Scalability:</strong> Our mobile apps are designed to
                grow with your business, ensuring they can handle increased user
                demand and evolving business requirements.
              </li>
              <li>
                <strong>Robust Security:</strong> Rest easy knowing that your
                mobile app is built with robust security measures to protect
                both your business and your users.
              </li>
            </ul>
            <p>
              Transform your business's digital presence with SystemLogic mobile
              application development expertise. Join us in creating a future
              where innovation meets seamless mobile experiences.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
export default MobileApp;
