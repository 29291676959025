import React from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import Sidenavbar from "../components/sidenavbar";
import Preloader from '../components/preloader';

function Blockchain() {
  return (
    <div>
       
      <Sidenavbar />
       <Link to="/"><Header /></Link>
      <div
        className="
        page-header
        position-relative
        d-flex
        align-items-center
        justify-content-center
        overflow-hidden
      "
      >
        <img
          className="position-absolute"
          src="../assets/images/services/business.jpg"
          alt=""
        />
        <div
          className="
          position-absolute
          w-100
          h-100
          d-flex
          flex-column
          align-items-start
          justify-content-end
        "
        >
          <div className="position-relative w-100 h-100">
            <div className="content-container w-100 h-100">
              <div
                className="
                container
                w-100
                h-100
                d-flex
                flex-column
                align-items-start
                justify-content-center
              "
              >
                <p>
                  <span>Blockchain</span> Development
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="page">
        <div className="content-container">
          <div className="container">
            <p>
              Embrace the future of business transformation with our
              cutting-edge blockchain development services. As a leading
              provider in the industry, we specialize in harnessing the power of
              blockchain to elevate your business processes and drive
              unprecedented growth. Here's why partnering with us for blockchain
              development is the key to unlocking your company's full potential:
            </p>
            <br />
            <p>
              Our team of blockchain experts boasts over 200 years of collective
              experience, ensuring that your business receives unparalleled
              expertise and innovative solutions. We don't just provide
              services; we immerse ourselves in understanding your unique
              requirements to deliver customized blockchain solutions that
              propel your business forward.
            </p>
            <ul>
              <li>
                <strong>Synergizing Non-Core Functions:</strong> Through a
                combination of advanced technology and subject matter expertise,
                we streamline non-core functions, allowing you to focus on what
                truly matters – your core business.
              </li>
              <li>
                <strong>Payroll Processing Standardization:</strong> Experience
                the benefits of best practices, digitalization, cost reduction,
                and hassle-free administration with our outsourced payroll
                services.
              </li>
              <li>
                <strong>Accounting and Finance Excellence:</strong> Our
                professional services bring depth to your financial functions,
                offering cost-effective solutions, record-keeping precision,
                flexible reporting, and strategic business reviews.
              </li>
              <li>
                <strong>Internal Audit and Controls:</strong> Gain a competitive
                edge with our customized strategic risk planning, benchmarking
                against best practices, and comprehensive oversight
                implementation.
              </li>
              <li>
                <strong>IT Services Redefined:</strong> Elevate your business's
                technical capabilities with our offshore technical support,
                software development, and IT infrastructure expertise.
              </li>
            </ul>
            <br />
            <p>
              Partnering with us means reaping a multitude of benefits for your
              business:
            </p>
            <ul>
              <li>
                <strong>Overall Cost Savings:</strong> Maximize efficiency while
                minimizing costs with our cost-effective blockchain solutions.
              </li>
              <li>
                <strong>Operational Flexibility:</strong> Adapt to evolving
                business landscapes seamlessly, thanks to our agile and flexible
                blockchain development approach.
              </li>
              <li>
                <strong>Accelerated Go-to-Market Execution:</strong> Stay ahead
                of the competition with our rapid deployment strategies,
                ensuring a swift market entry for your innovations.
              </li>
              <li>
                <strong>Access to Tools and Processes:</strong> Leverage our
                state-of-the-art tools and processes to stay at the forefront of
                technological advancements.
              </li>
              <li>
                <strong>Business Agility:</strong> Embrace change effortlessly,
                aligning your business with dynamic growth criteria and
                ever-changing industry scopes.
              </li>
            </ul>
            <p>
              Elevate your business to new heights with our top-tier blockchain
              development services. Join hands with us, and let's build a future
              where innovation meets success.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
export default Blockchain;
