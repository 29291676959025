import React from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import Sidenavbar from "../components/sidenavbar";
import Preloader from '../components/preloader';

function Graphic() {
  return (
    <div>
       
      <Sidenavbar />
      <Link to="/"><Header /></Link>
      <div
        className="
        page-header
        position-relative
        d-flex
        align-items-center
        justify-content-center
        overflow-hidden
      "
      >
        <img
          className="position-absolute"
          src="../assets/images/services/software.jpg"
          alt=""
        />
        <div
          className="
          position-absolute
          w-100
          h-100
          d-flex
          flex-column
          align-items-start
          justify-content-end
        "
        >
          <div className="position-relative w-100 h-100">
            <div className="content-container w-100 h-100">
              <div
                className="
                container
                w-100
                h-100
                d-flex
                flex-column
                align-items-start
                justify-content-center
              "
              >
                <p>
                  <span>Graphic</span> Designing
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="page">
        <div className="content-container">
          <div className="container">
            <p>
              Elevate your business aesthetics with our unparalleled graphic
              design solutions. We specialize in crafting visually stunning
              designs that resonate with your brand identity, captivate your
              audience, and leave a lasting impression. Explore the world of
              creative possibilities with our graphic design expertise:
            </p>
            <ul>
              <li>
                <strong>Branding Excellence:</strong> Immerse your brand in a
                visual identity that speaks volumes. Our graphic design services
                include creating compelling logos, brand collateral, and visual
                elements that embody your brand essence.
              </li>
              <li>
                <strong>Print and Digital Design:</strong> From eye-catching
                print materials to engaging digital designs, we cover it all.
                Let your marketing materials, social media graphics, and website
                visuals stand out with our meticulous design approach.
              </li>
              <li>
                <strong>UI/UX Innovation:</strong> Enhance user experiences with
                intuitive and visually appealing interfaces. Our graphic design
                team excels in creating seamless user journeys, ensuring your
                digital platforms are both functional and aesthetically
                pleasing.
              </li>
              <li>
                <strong>Illustrations and Infographics:</strong> Bring your
                ideas to life through captivating illustrations and
                infographics. We transform complex concepts into easily
                digestible visuals, making your messaging more impactful.
              </li>
            </ul>
            <br />
            <p>
              Our experience spans a diverse range of projects, allowing us to
              offer comprehensive graphic design services tailored to your
              unique needs. Here's what you can expect from our core
              implementation and deployment services:
            </p>
            <ul>
              <li>
                <strong>Dedicated Project Management Support:</strong> Our team
                ensures smooth project coordination, keeping you informed and
                involved at every stage of the design process.
              </li>
              <li>
                <strong>Installation on Your Preferred Platform:</strong>{" "}
                Whether it's print or digital, we seamlessly install and
                integrate your designs onto the platforms that matter most to
                your business.
              </li>
              <li>
                <strong>Data Mapping and Migration:</strong> We handle the
                transition seamlessly, mapping and migrating your graphic assets
                with precision and care.
              </li>
              <li>
                <strong>Design Workshops and Documentation:</strong> Collaborate
                with our designers in workshops to align visions and goals. We
                provide thorough documentation for transparency and clarity.
              </li>
              <li>
                <strong>Configuration Changes:</strong> Implement any necessary
                design changes to optimize visual appeal and align with evolving
                brand strategies.
              </li>
              <li>
                <strong>Troubleshooting:</strong> Our team is ready to address
                any design challenges swiftly and efficiently, ensuring a
                seamless graphic design experience.
              </li>
              <li>
                <strong>Acceptance Testing and User Training:</strong>{" "}
                Prioritize user satisfaction with thorough testing and user
                training, guaranteeing that your graphic designs meet and exceed
                expectations.
              </li>
            </ul>
            <p>
              Transform your brand presence with our exceptional graphic design
              services. Join hands with us, where creativity meets strategy, to
              unlock a visually compelling future for your business.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
export default Graphic;
