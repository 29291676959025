import React from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import Sidenavbar from "../components/sidenavbar";
import Preloader from '../components/preloader';

function Software() {
  return (
    <div>
       
      <Sidenavbar />
      <Link to="/"><Header /></Link>
      <div
        className="
        page-header
        position-relative
        d-flex
        align-items-center
        justify-content-center
        overflow-hidden
      "
      >
        <img
          className="position-absolute"
          src="../assets/images/products/Software-development.jpg"
          alt=""
        />
        <div
          className="
          position-absolute
          w-100
          h-100
          d-flex
          flex-column
          align-items-start
          justify-content-end
        "
        >
          <div className="position-relative w-100 h-100">
            <div className="content-container w-100 h-100">
              <div
                className="
                container
                w-100
                h-100
                d-flex
                flex-column
                align-items-start
                justify-content-center
              "
              >
                <p>
                  <span>Software</span> Development
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="page">
        <div className="content-container">
          <div className="container">
            <p>
              Elevate your business with SystemLogic, a leading global provider
              of IT solutions and enterprise applications. We specialize in
              custom development, systems integration, and top-notch technical
              services to address your unique business needs.
            </p>
            <p>
              <strong>SoftwareCraft</strong> is our flagship portal offering a
              suite of tools tailored for the construction and contracting
              industry. Within its robust modules, the "Blueprint Insight"
              feature allows users to seamlessly import CAD diagrams. The
              interactive tools within the "Take Off" module empower users to
              mark and measure material requirements efficiently,
              revolutionizing the construction process.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
export default Software;
