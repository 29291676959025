import React from 'react';
import {  BrowserRouter as Router,  Routes,  Route} from "react-router-dom";
import './App.css';
import About from './pages/aboutus.js';
import Contact from './pages/contact.js';
import Home from './pages/home.js';
import Team from './pages/team.js';
import Career from './pages/careers.js';
import Web from './services/web.js';
import MobileApp from './services/mobileApp.js';
import Blockchain from './services/blockchain.js';
import Graphic from './services/graphic.js';
import Software from './products/software.js';
import Dapps from './products/dapp.js';
import GoToTop from './components/GoToTop.js';
import { ThemeProvider } from "styled-components";



function App() {
  const theme = {
    colors: {
      btn: "#007bff",
    shadow: "rgba(0, 0, 0, 0.2)",
    },
    media: {
      mobile: "600px",
    },
  };
  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />}>
          </Route>
          <Route path="/about-us" element={<About />}>
          </Route>
          <Route path="/team" element={<Team />}>
          </Route>
          <Route path="/contact" element={<Contact />}>
          </Route>
          <Route path="/careers" element={<Career />}>
          </Route>
          
          <Route path="/web-development" element={<Web />}>
          </Route>
          <Route path="/mobile-app-development" element={<MobileApp />}>
          </Route>
          <Route path="/blockchain-development" element={<Blockchain />}>
          </Route>
          <Route path="/graphic-designing" element={<Graphic />}>
          </Route>
          <Route path="/software-development" element={<Software />}>
          </Route>
          <Route path="/dapps-nft-development" element={<Dapps />}>
          </Route>
          </Routes>
          <GoToTop/>
      </Router>
    </div>
    </ThemeProvider>
  );
}

export default App;
