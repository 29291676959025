import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import Header from '../components/header';
import Footer from '../components/footer';
import Sidenavbar from '../components/sidenavbar';
import Herosection from '../components/herosection';
import Services from '../components/services';
import Product from '../components/product';
import Aboutus from '../components/aboutus';
import Partner from '../components/partner';
import Preloader from '../components/preloader';


function Home() {
  return (
    <div className="App">
      <Sidenavbar />
      <Link to="/"> 
        <Header/>
      </Link>
            <Herosection/>
            <Aboutus />
            <Services />
            <Product />
            {/* <Partner /> */}
            <Footer/>
        </div>
  );
}

export default Home;
