import React from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import Sidenavbar from "../components/sidenavbar";
import Preloader from '../components/preloader';

function Dapps() {
  return (
    <div>
       
      <Sidenavbar />
      <Link to="/"><Header /></Link>

      <div
        className="
        page-header
        position-relative
        d-flex
        align-items-center
        justify-content-center
        overflow-hidden
      "
      >
        <img
          className="position-absolute"
          src="../assets/images/products/erp.jpg"
          alt=""
        />
        <div
          className="
          position-absolute
          w-100
          h-100
          d-flex
          flex-column
          align-items-start
          justify-content-end
        "
        >
          <div className="position-relative w-100 h-100">
            <div className="content-container w-100 h-100">
              <div
                className="
                container
                w-100
                h-100
                d-flex
                flex-column
                align-items-start
                justify-content-center
              "
              >
                <p>
                  <span>Dapps NFT's Development</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="page">
        <div className="content-container">
          <div className="container">
            <div className="">
              <h4>Decentralized Applications (DApps)</h4>
            </div>
            <p>
              Whether you are a developer, entrepreneur, or technology
              enthusiast, you can engage with and contribute to decentralized
              applications (DApps), the cutting-edge solution in the blockchain
              space. Register and start building or utilizing DApps, the most
              innovative and user-friendly option in decentralized application
              development. With a robust and intuitive platform, coupled with
              comprehensive training and support, you will have the necessary
              tools to create or promote some of the best DApps in the market.
            </p>
            <div className="mt-5">
              <h4>CryptoForge</h4>
            </div>
            <p>
              CryptoForge is a comprehensive blockchain solution for secure data
              management. It is an intelligently designed, cloud-based platform
              with multiple modules to manage your cryptographic assets
              securely. From encryption to decentralized identity management,
              CryptoForge provides a powerful, flexible, and secure system to
              handle your cryptographic resources in a modern and efficient way.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
export default Dapps;
