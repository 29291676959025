import React from "react";
import { Link, useLocation } from "react-router-dom";
import Header from '../components/header';
import Footer from '../components/footer';
import Sidenavbar from '../components/sidenavbar';
import Preloader from '../components/preloader';
import { useEffect } from "react";

function Careers() {

    const routerLocation = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [routerLocation]);
    return (
        <div>

 
            <Sidenavbar />
             <Link to="/"><Header /></Link>
            <div className="
        page-header
        position-relative
        d-flex
        align-items-center
        justify-content-center
        overflow-hidden
      ">
        <img className="position-absolute" src="./assets/images/careers.jpg" alt="" />
        <div className="
          position-absolute
          w-100
          h-100
          d-flex
          flex-column
          align-items-start
          justify-content-end
        ">
            <div className="position-relative w-100 h-100">
                <div className="content-container w-100 h-100">
                    <div className="
                container
                w-100
                h-100
                d-flex
                flex-column
                align-items-start
                justify-content-center
              ">
                        <p><span>Careers</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <section className="page">
        <div className="content-container">
            <div className="container">
                <p>
                    We are an equal opportunity employer and we commit to maintain a
                    diverse and healthy workplace.
                </p>
                <p>
                    System Logic partners with professionals who are energetic and
                    focused enough to accept new challenges and achieve their goals with
                    integrity, passion, synergy and discipline. Our employees work hand
                    in hand with our customers to help them achieve highest level of
                    customer service. Our teams help us to define SystemLogic’s accelerated
                    growth and direction.
                </p>
                <p>
                    Our ethics and business practices provide an opportunity for each
                    and every individual to grow exponentially with all the
                    entrepreneurial spirit.
                </p>
                <p>
                    Please fill your details below. Our expert team will review your
                    application and find the best fit for you.
                </p>
                <br />
                <h4>Career Form</h4>
                <form action="forms/careers.php" method="post" role="form" enctype="multipart/form-data">
                    <div className="controls">
                        <br />
                        <div className="row">
                            <div className="col-12 col-md-6 form-group">
                                <input type="text" className="form-control" name="name" id="name" placeholder="Name*"
                                    required />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                                <input type="email" className="form-control" name="email" id="email"
                                    placeholder="Email Address*" required />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6 form-group">
                                <input type="number" className="form-control" name="contactNum" id="contactNum"
                                    placeholder="Contact Number*" required />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                                <select name="jobVacancy" id="jobVacancy" className="form-select"
                                    aria-label="Default select example" required>
                                    <option value="" selected>Choose Job Vacancy</option>
                                    <option value="sqa">Software Quality Assurance</option>
                                    <option value="webDev">Web Developer</option>
                                    <option value="it">IT Engineer</option>
                                </select>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6 form-group">
                                <select name="jobLocation" id="jobLocation" className="form-select"
                                    aria-label="Default select example" required>
                                    <option value="" selected>Choose Job Location</option>
                                    <option value="Jeddah">Jeddah, Saudi Arabia</option>
                                    <option value="Karachi">Karachi, Pakistan</option>
                                    <option value="XYZ">XYZ, United Kingdom</option>
                                </select>
                            </div>
                            <div className="col-12 col-md-6 form-group">
                                <input type="file" className="form-control" name="cvResume" id="cvResume" accept=".doc,.docx,.pdf" required />
                            </div>
                        </div>
                        <br />
                        <div className="text-center">
                            <button type="submit" className="btn btn-send" id="jobApplyBtn">
                                Apply
                            </button>
                        </div>
                    </div>
                </form>
                <br /><br />
                <p>For any further queries, reach us at</p>
                <span><a href="mailto:info@SystemLogic.com">info@systemLogic.com</a></span>
            </div>
        </div>
    </section>

            <Footer />
        </div>
    );
}
export default Careers;